/* Fonts Imports */


// Regular
@font-face {
  font-family: 'MyriadPro';
  src: url('../assets/fonts/MyriadPro/MyriadPro-Regular.woff2') format('woff2'),
      url('../assets/fonts/MyriadPro/MyriadPro-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

// Regular Italic
@font-face {
  font-family: 'MyriadPro';
  src: url('../assets/fonts/MyriadPro/MyriadPro-RegularItalic.woff2') format('woff2'),
      url('../assets/fonts/MyriadPro/MyriadPro-RegularItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

// Semibold
@font-face {
  font-family: 'MyriadPro';
  src: url('../assets/fonts/MyriadPro/MyriadPro-Semibold.woff2') format('woff2'),
      url('../assets/fonts/MyriadPro/MyriadPro-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

// Semibold Italic
@font-face {
  font-family: 'MyriadPro';
  src: url('../assets/fonts/MyriadPro/MyriadPro-SemiboldItalic.woff2') format('woff2'),
      url('../assets/fonts/MyriadPro/MyriadPro-SemiboldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

// Bold
@font-face {
  font-family: 'MyriadPro';
  src: url('../assets/fonts/MyriadPro/MyriadPro-Bold.woff2') format('woff2'),
      url('../assets/fonts/MyriadPro/MyriadPro-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

// Bold Italic
@font-face {
  font-family: 'MyriadPro';
  src: url('../assets/fonts/MyriadPro/MyriadPro-BoldItalic.woff2') format('woff2'),
      url('../assets/fonts/MyriadPro/MyriadPro-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}
