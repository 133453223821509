@import "src/scss/component-utilities";
@import "src/scss/button";

.Home {
  min-height: $canvas-height;
  background-color: $white;
  padding: rem(75) 0 $desktop-spacing 0;
  margin: 0 auto;
  text-align: center;

  @include media-max('breakpoint') {
    padding: rem(35) 0 $desktop-spacing 0;
  }
}

.HomeTitleContainer {
  padding: 0 $mobile-spacing;
}

.ScenesContainer {
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  max-width: $content-max-width;
  margin: $desktop-spacing auto 0;
  z-index: 2;
  position: relative;

  @include media-max('breakpoint') {
    margin-top: rem(15);
  }
}

.Scene {
  -webkit-flex: 0 0 50%;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
  padding: 0 $desktop-spacing;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  h4 {
    margin-top: auto;
    margin-bottom: auto;
  }

  @include media-max('breakpoint') { // update to breakpoint from CHJ21-296-header-ux PR
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0 $mobile-spacing;
    margin-top: $mobile-spacing;
  }
}

.SceneImage {
  // Make the image container 16:9 aspect ratio for better image loading
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
  margin-top: rem(20);

  img {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
}

.SceneButton {
  color: $primary-color !important;
  margin: $mobile-spacing auto;
  width: 100%;
}

.Button {
  display: block;
}

.BackgroundGraphic {
  width: 100%;
  height: 35vh;
  display: flex;
  position: absolute;
  bottom: 0;
  overflow: hidden;
  z-index: 1;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    align-self: center;
  }
}
