@import "src/scss/component-utilities";
@import "src/scss/button";

.Header {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 11;
  padding: 0 $desktop-spacing;
  height: $header-height;
	align-items: center;
  justify-content: flex-start;
	flex-wrap: wrap;
  color: $primary-color;
  background-color: $white;
  box-shadow: 0px 3px 15px rgba(0,0,0,0.50);

  @include media-max('breakpoint') {
    padding: 0 $mobile-spacing;
  }

  @include media-max('tablet') {
    position: relative;
    height: auto;
    padding: rem(15) $mobile-spacing;
    justify-content: center;
  }
}

.Logo {
  width: 100%;
  max-width: rem(182); // match design comp width for logo
}

.HeaderNav {
  width: 100%;

  @include media-max('tablet') {
    justify-content: center;
    margin-left: 0;
    width: 100%;
  }
}

.HeaderNav ul {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  width: 100%;
}

.HeaderNav li {
	display: inline;
  margin: 0 rem(10);

  a, a:visited, a:hover {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.HeaderNavLogo {
    margin-left: 0;
    margin-right: auto;

    @include media-min('tablet') {
      a {
        padding: 0;
      }
    }

    @include media-max('tablet') {
      flex-basis: 50%;
      a {
        justify-content: flex-start;
      }
    }
  }

  // Second Child - Product Button
  &.HeaderNavButton {
    margin-left: 0;

    @include media-max('tablet') {
      margin-top: rem(15);
      margin-right: 0;
      order: 3;
      flex-basis: 100%;
    }
  }

  // Last Child - OEP Link
  &.HeaderNavLink {
    margin-right: 0;

    @include media-max('tablet') {
      flex-basis: 50%;
      margin-left: 0;
      order: 1;
    }
  }

  &.HeaderNavLink a{
    font-size: rem(16);
    line-height: rem(16);
    @extend %fontSemiBold;
    color: $hyperlink-secondary;
    text-decoration: underline;
    padding: rem(13) 0 rem(12) rem(18);

    @include media-max('tablet') {
      justify-content: flex-end;
      padding: rem(13) 0 rem(12) rem(12);
    }

    &:visited {
      color: $hyperlink-secondary;
    }

    &:hover {
      color: $hyperlink-secondary-hover;
    }

    &:focus {
      color: $hyperlink-secondary;
    }

    &:active {
      color: $hyperlink-secondary-hover;
    }

  }

}
