/* Variables */

// Cardinal Style Guide
$cardinal-red: #EF3A42;
$cardinal-orange: #F79C59;
$cardinal-orange-pharmacy: #F57F29;
$cardinal-green: #6CC24E;
$cardinal-purple: #9B26B6;
$cardinal-blue: #64C8CE;
$cardinal-blue-efficiency: #00B0B9;

// colors
$white: #FFFFFF;
$black: #000000;
$primary-color: $black;
$secondary-color: #009EDC;
$tertiary-color: #15495D;
$error-color: #C5112E;
$background-color: $white;
$modal-highlight: #FCC954;
$dark-font: #000000;
$hotspot-text-color: $cardinal-red;
$tooltip-color: #0D274D;
$dark-background-color: #333333;
$footer-text-color: #999999;

// loader
$loader-color-1: $cardinal-red;
$loader-color-2: $cardinal-blue;
$loader-color-3: $black;

// buttons
$button-inactive: #DFDFDF;
$blue-button-active: #00BCEB;
$blue-button-hover: #007DBA;
$green-button-active: #74BF4B;
$green-button-hover: #309B42;
$red-button-hover: #BD0100;
$red-button-active: #9F0100;
$orange-button-hover: #F08536;
$orange-button-active: #EC7822;

// links
$hyperlink-hover: #BD0100;
$hyperlink-active: #9F0100;
$hyperlink-visited: #F79DA1;
$hyperlink-secondary: #78797C;
$hyperlink-secondary-hover: #000000;

// environment
$header-height: 100px;
$footer-height: 70px;
$mobile-footer-height: 120px;
$desktop-spacing: 50px;
$mobile-spacing: 20px;
$content-max-width: 1366px;
$canvas-height: calc(100vh - #{$header-height} - #{$footer-height});

// modal
$modal-header: #9E9EA2;

// breakpoints
$breakpoints: (
  "phone-small": 321px,
  "phone": 400px,
  "phone-wide": 480px,
  "phablet": 560px,
  "tablet-small": 640px,
  "tablet": 768px,
  "midpoint": 980px,
  "breakpoint": 1024px,
  "laptop-wide": 1080px,
  "laptop": 1200px,
  "desktop": 1366px,
  "desktop-mid": 1536px,
  "desktop-wide": 1920px,
);
