/* Font Placeholder Selectors */

%fontRegular {
  font-family: 'MyriadPro', 'myriad-pro','Myriad Pro', "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-style: normal;
}

%fontRegularItalic {
  font-family: 'MyriadPro', 'myriad-pro','Myriad Pro', "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-style: italic;
}

%fontSemiBold {
  font-family: 'MyriadPro', 'myriad-pro','Myriad Pro', "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
}

%fontSemiBoldItalic {
  font-family: 'MyriadPro', 'myriad-pro','Myriad Pro', "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: italic;
}

%fontBold {
  font-family: 'MyriadPro', 'myriad-pro','Myriad Pro', "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-style: normal;
}

%fontBoldItalic {
  font-family: 'MyriadPro', 'myriad-pro','Myriad Pro', "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-style: italic;
}
