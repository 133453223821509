@import "src/scss/component-utilities";

.Footer {
  display: flex;
  padding: 0 $desktop-spacing;
  height: $footer-height;
	align-items: center;
  justify-content: flex-start;
	flex-wrap: wrap;
  color: $footer-text-color;
  background-color: $dark-background-color;

  @include media-max('breakpoint') {
    padding: 0 $mobile-spacing;
  }

  @include media-max('tablet') {
    padding: rem(15) $mobile-spacing;
    height: auto;
    min-height: $footer-height;
    justify-content: center;
  }
}

.FooterContainer {
  display: flex;
  align-items: center;
  width: 100%;

  @include media-max('tablet') {
    justify-content: center;
    flex-direction: column;
    width: 100%;
  }
}

.FooterContainer ul {
  display: flex;
}

.FooterNav {
  margin-left: auto;

  @include media-max('tablet') {
    margin-left: 0;
  }
}

.FooterCopyright {

  li {
    // 1px extra padding top to account for line height issue with the font (discussed with Hailey)
    margin-top: rem(1);
  }

  @include media-max('tablet') {
    margin-top: rem(5);
    order: 2;

    li {
      font-size: rem(14);
      line-height: rem(20);
    }
  }
}

.FooterNav {

  @include media-max('tablet') {
    order: 1;
  }
}

.FooterNav li {
	display: inline;
  // 1px extra padding top to account for line height issue with the font (discussed with Hailey)
  margin: rem(1) rem(10) 0 rem(10);
  position: relative;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: rem(16);
    line-height: rem(22);
    padding: rem(5) rem(10);
    color: $footer-text-color;

    @include media-max('tablet') {
      font-size: rem(14);
      line-height: rem(20);
    }

    &:hover {
      color: $white;
    }
  }

  &:first-child {
    margin-left: 0;
  }

  &:first-child:after {
    content: "|";
    position: absolute;
    top: 0;
    right: rem(-12);
    bottom: 0;
    margin: auto;
    display: block;
    font-size: rem(18);
    height: rem(22);
  }

  &:last-child {
    margin-right: 0;
    a {
      padding-right: 0;
    }
  }
}
