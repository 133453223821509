/* Mixins */

// mixin for media-min
@mixin media-min($breakpoint) {
  // If the key exists in the map
  @if map-has-key($breakpoints, $breakpoint) {
    // Prints a media query based on the value
    @media (min-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  }

  // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}

// mixin for media-max
@mixin media-max($breakpoint) {
  // If the key exists in the map
  @if map-has-key($breakpoints, $breakpoint) {

    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    // Prints a media query based on the value - 1px for max-width based media queries
    @media (max-width: ($breakpoint-value - 1)) {
      @content;
    }
  }

  // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}

// mixin for transition
@mixin transition ($transition) {
  transition: $transition;
  -webkit-transition: $transition;
  -moz-transition: $transition;
  -ms-transition: $transition;
  -o-transition: $transition;
}

// mixin for border-radius
@mixin border-radius($border-radius){
  border-radius: $border-radius;
  -webkit-border-radius: $border-radius;
  -moz-border-radius: $border-radius;
  -ms-border-radius: $border-radius;
  -o-border-radius: $border-radius;
}

// mixin for transform
@mixin transform($transform){
  transform: $transform;
  -webkit-transform: $transform;
  -moz-transform: $transform;
  -ms-transform: $transform;
  -o-transform: $transform;
}
