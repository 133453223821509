@import "component-utilities";

/* Typography */

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  line-height: 1.2;
  margin-top: 0;
  margin-bottom: rem(18);

  @include media-max('breakpoint') {
    margin-bottom: rem(10)
  }

  a {
    text-decoration: none;
  }
}

h1 {
  font-size: rem(48);
  line-height: rem(58);
  @extend %fontSemiBold;
  color: $cardinal-blue;

  @include media-max('breakpoint') {
    font-size: rem(30);
    line-height: rem(35);
  }
}

h2 {
  font-size: rem(30);
  line-height: rem(35);
  @extend %fontRegular;

  @include media-max('breakpoint'){
    font-size: rem(22);
    line-height:rem(28);
  }
}

h3 {
  font-size: rem(26);
  line-height: rem(26);
  @extend %fontBold;

  @include media-max('breakpoint'){
    font-size: rem(20);
    line-height: rem(25);
  }
}

h4 {
  font-size: rem(22);
  line-height: rem(26);
  @extend %fontSemiBold;

  @include media-max('breakpoint'){
    font-size: rem(16);
    line-height: rem(20);
  }
}

h5 {
  font-size: rem(16);
  @extend %fontBold;
}

h6 {
  font-size: rem(16);
  @extend %fontSemiBold;
}

p {
  font-size: rem(18);
  line-height: rem(26);
  @extend %fontRegular;
  margin-top: 0;
  margin-bottom: rem(18);

  @include media-max('breakpoint'){
    font-size: rem(16);
    line-height: rem(24);
  }
}

a, a[href^="tel:"] {
  @include transition(all 0.3s ease);
  font-size: rem(18);
  line-height: rem(26);
  @extend %fontRegular;
  color: $cardinal-red;
  text-decoration: none;

  @include media-max('breakpoint'){
    font-size: rem(16);
    line-height: rem(24);
  }

  &:visited {
    color: $hyperlink-visited;
  }

  &:hover {
    color: $hyperlink-hover;
  }

  &:focus {
    color: $hyperlink-active;
  }

  &:active {
    color: $hyperlink-active;
  }

}

sup {
  top: -.20rem;
  left: .025rem;
}

ul,
ol {
  margin: 0;
  padding: 0;
}

li, span {
  @extend %fontRegular;
}
