@import "src/scss/component-utilities";

.CookieNotice {
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  text-align: left;
  width: 100%;
  max-width: 100%;
  height: rem(60);
  background-color: $cardinal-blue;
  color: white;
  padding: rem(15) $desktop-spacing;
  font-size: rem(18);
  @extend %fontRegular;
  z-index: 3;

  @include media-max('breakpoint') {
    position: fixed;
    padding: rem(15) $mobile-spacing;
  }

  > div {
    width: calc(100% - #{$desktop-spacing});
  }

  a, a:visited, a:hover {
    text-decoration: underline;
    font-weight: bold;
    color: $white;
  }
}

$ExitWidth: 28px;
$ExitLayerHeight: 4px;
$ExitColor: $white;

.CookieNoticeClose {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  right: $desktop-spacing;
  width: $ExitWidth;
  height: $ExitWidth / 2;
  background: none;
  border: 0;
  padding: rem(10) rem(0) rem(14) rem(0);

  @include media-max('breakpoint') {
    right: $mobile-spacing;
  }

  &::before, &::after {
    display: block;
    position: absolute;
    content: "";
    z-index: 2;
    width: $ExitWidth;
    height: $ExitLayerHeight;
    background: $ExitColor;
  }
  &::before {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  &::after {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}
