@import "component-utilities";

/* Buttons */
button, input[type="button"] {
  cursor: pointer;
  margin: 0;
  outline: 0;
  border: 0;
}

.Button {
  display: flex;
  align-items: center;
  justify-content: center;
  @extend %fontSemiBold;
  font-size: rem(16);
  line-height: rem(16);
  color: $white;
  border: none;
  cursor: pointer;
  text-align: center;
  margin: 0;
  // 1px extra padding top to account for line height issue with the font (discussed with Hailey)
  padding: rem(13) rem(18) rem(12) rem(18);
  border-radius: rem(6);
  min-width: rem(100);
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  text-decoration: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -ms-touch-action: none;
  -webkit-font-smoothing: antialiased;
  @include transition(all 0.3s ease);
  &:hover {
    color: $white;
    text-decoration: none;
  }
  &:focus {
    color: $white;
    text-decoration: none;
  }
  &:active {
    color: $white;
    text-decoration: none;
  }
  &:visited {
    color: $white;
    text-decoration: none;
  }
  &.ButtonOrange {
    color: $white;
    background-color: $cardinal-orange;
    &:visited {
      background-color: $cardinal-orange;
      // &:hover {
      //   background-color: $orange-button-hover;
      // }
    }
    &:hover {
      background-color: $orange-button-hover;
    }
    // &:focus {
    //   background-color: $cardinal-orange;
    // }
    &:active {
      background-color: $orange-button-active;
    }
  }
  &.ButtonRed {
    color: $white;
    background-color: $cardinal-red;
    &:visited {
      background-color: $cardinal-red;
      // &:hover {
      //   background-color: $red-button-hover;
      // }
    }
    &:hover {
      background-color: $red-button-hover;
    }
    // &:focus {
    //   background-color: $cardinal-red;
    // }
    &:active {
      background-color: $red-button-active;
    }
  }
  &.ButtonIcon {
    flex-direction: column;
    width: rem(75);
    height: rem(79);
    img {
      max-width: 100%;
      margin-top: rem(5);
    }
  }
  &.ButtonIconInline {
    padding: 0 rem(15) 0 rem(15);
    flex-direction: row;
    height: rem(41);
    img {
      width: rem(25);
      padding: rem(5);
    }
  }
  &.ButtonWide {
    width: 100%;
  }
  &:disabled {
    opacity: .5;
    pointer-events: none;
    cursor: default;
  }
}
