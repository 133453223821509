@import "src/scss/component-utilities";
@import "src/scss/button";

.PasswordGateContainer {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: $white;
  min-height: $canvas-height;
  text-align: center;
  @extend %fontRegular;
  z-index: 10;
}

.PasswordGate {
  position: relative;
  transform: translateY(-100%);
  @media only screen and (max-height: 800px) {
    @include transform(scale(80%)translateY(-100%));
  }
  @media only screen and (max-height: 700px) {
    @include transform(scale(70%)translateY(-100%));
  }
  @media only screen and (max-height: 600px) {
    @include transform(scale(60%)translateY(-100%));
  }
  @media only screen and (max-height: 500px) {
    @include transform(scale(50%)translateY(-100%));
  }
}

.Header {

}

.Subheader {

}

.Label {

}

.PasswordForm {
  font-size: rem(16);
  line-height: rem(16);
}

.PasswordRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: rem(10);
}

.PasswordInput {
  width: 35vw;
  padding: 0 rem(8) 0 rem(8);
}

.ErrorMessage {
  position: absolute;
  left: 50%;
  margin-top: rem(16);
  transform: translateX(-50%);
  color: $error-color;
}

.BackgroundGraphic {
  width: 100%;
  height: 35vh;
  display: flex;
  position: absolute;
  bottom: 0;
  overflow: hidden;
  z-index: -1;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    align-self: center;
  }
}
